import Vue from 'vue';

const castStore = Vue.observable({
    /**
    * Sirve para establecer una bandera que indique si el navegador soporta o no el casteo de contenidos
    * Esta propiedad se establece por default en false ya que si no existe soporte para casteo
    * la función __onGCastApiAvailable nunca se ejecuta y por ende nunca se setearía el valor en true/false
    * si __onGCastApiAvailable no se ejecuta, quiere decir que no existe soporte en el navegador para casteo (por ej. en firefox)
     */
    supportedBrowser: false,
});

/**
 * Obtiene el soporte de casteo
 */
const setSupport = (support) => {
    if(support != undefined && typeof support === 'boolean')
    {
        castStore.supportedBrowser = support;
    } else {
        castStore.supportedBrowser = false;   
    }   
}

/**
 * Obtiene el soporte de casteo
 */
const haveSupport = () => {
    return castStore.supportedBrowser;   
}


export { setSupport, haveSupport };
