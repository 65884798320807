/**
 * Declaración de variables globales
 */

const epg = {};

epg.install = function (Vue) {
    Vue.prototype.$epg = {
        /**
         * Contiene la información de epg
         * - Caso de uso actual: player, vista de guia vertical
         * - Caso de uso futuro: tv en vivo
         */
        data: [],
        /**
         * Mantiene el valor de última actualización. Es útil para determinar cuando debe actualizarse la info de epg desde el core.
         */
        lastUpdated: null,
        /**
         * Sirve para indicar de que existe un trabajo de actualización de epg en progreso.
         * En ese caso, los llamados quedan pending hasta que finaliza el trabajo actual y retornan el resultado del trabajo realizado.
         */
        updateInProgress: false,
        /**
         * Indice para indicar la ventana de epg a la que debe recurrir. Ej: si es 12, abre de 12 a 18
         * - Caso de uso actual: en vista de guía epg
         */
        initialIndex: 0,
        /**
         * Indice para indicar que columna de las visibles debe estar activa. Ej. valores posibles de 0 a 4 (en epg vertical solo se muestran 5 columnas)
         * - Caso de uso actual: en vista de guía epg
         */
        activeColumnIndex: 0,

        /**
         *  último canal focuseado en guía tradicional
         *
         */
        focusedChannelLcn: 1,

        /**
         * último programa focuseado en guía tradicional
         *
         */
        focusedProgramIdAsset: 0,


        /**
         * Controla si la fecha de última actualización es mayor a 2 horas.
         */
        expiredData: () => {
            const dateNow = new Date().getTime() / 1000;
            const dateTarget = dateNow - ( 2 * 60 * 60 );


            if(!Vue.prototype.$epg.lastUpdated){
                return true;
            }

            if(Vue.prototype.$epg.lastUpdated <= dateTarget){
                return true;
            }

            return false;
        },

        /**
         * Actualiza la lista de epg
         */
        update: () => {
            const filters =  {
                    rango: {
                        desde: new Date(Date.now() - 24*60*60*1000), 
                        hasta: new Date(Date.now() + 36*60*60*1000)
                    }}

            console.log('call update epg');

            return new Promise((resolve) => {
                if(Vue.prototype.$epg.updateInProgress){
                    const checkInterval = window.setInterval(() => {
                        //console.log('Esperando información de epg');
                        if(!Vue.prototype.$epg.updateInProgress){
                            window.clearInterval(checkInterval);
                            resolve(Vue.prototype.$epg.data);
                        }
                    },1000);
                } else {
                    if(Vue.prototype.$epg.expiredData()){
                        //console.log('iniciando carga de epg');
                        Vue.prototype.$epg.updateInProgress = true;
                        telecentro.tplay.core.epg.cargarEpg(filters.rango.desde, filters.rango.hasta, (r) => {
                            const res = Object.values(r);
                            // console.log('res en crudo',res);
                            const epg = res.map((item, index) => {
                                const channel = {
                                    id: index,
                                    name: res[index][0].channel.name,
                                    lcn: res[index][0].channel.lcn,
                                    dvbTriplet: res[index][0].channel.dvbTriplet,
                                    logo: res[index][0].channel.logo,
                                    logoCanal: (res[index][0].isFakeProgram) ? res[index][0].channel.logoCanal : res[index][0].channel.logo,
                                    idChannel: res[index][0].channel.id,
                                    selected: false,
                                    programs: [],
                                };
                                item.map((e, eIndex) => {
                                    e.channel.id = (e.isFakeProgram) ? e.channel.idChannel : e.channel.id;
                                    channel.programs.push({
                                        isFakeProgram: e.isFakeProgram,
                                        actors: e.actors,
                                        audio: e.audio,
                                        categoria: e.categoria,
                                        certificate: e.certificate,
                                        certificateCode: e.certificateCode,
                                        csasname: e.csasname,
                                        director: e.director,
                                        dispose: e.dispose,
                                        duracionTotalSegundos: e.duracionTotalSegundos,
                                        edad: e.edad,
                                        endHour: e.endHour,
                                        fechaFin: e.fechaFin,
                                        fechaInicio: e.fechaInicio,
                                        grabado: e.grabado,
                                        recordData: e.recordData,
                                        sub: e.sub,
                                        synopsis: e.synopsis,
                                        idAsset: e.idAsset,
                                        title: e.title,
                                        poster: e.poster,
                                        status: e.status,
                                        channel: e.channel,
                                        startHourUnixTime: e.startHourUnixTime,
                                        endHourUnixTime: e.endHourUnixTime,
                                        startHour: e.startHour,

                                    });
                                });
                                return channel;
                            });
                            //obs.data = epg;
                            Vue.prototype.$epg.data = epg;
                            Vue.prototype.$epg.lastUpdated = new Date().getTime() / 1000;
                            Vue.prototype.$epg.updateInProgress = false;
                            //console.log('fin carga de epg');
                            resolve(Vue.prototype.$epg.data);
                        }, (err) => {
                            //console.log('[ OBS - EPG --> update] no se pudo actualizar la lista epg');
                            resolve(Vue.prototype.$epg.data);
                        });
                    } else {
                        resolve(Vue.prototype.$epg.data);
                    }
                }
            })
        },

        /**
        * Para no ir a buscar el status al servidor (optimizar tiempos)
        * Se puede calcular el status con el start y end time
        * Toma como parametro un programa y devuelve un string 'PRESENTE', 'PASADO', 'FUTURO'
        */
        getStatus: (program) => {
            const timeNow = parseInt(new Date().getTime() / 1000);
            if(program.status === 'Recorded' || program.status === 'Planned'){
                return program.status;
            }
            if(program.endHourUnixTime < timeNow){
                return 'PASADO';
            } else if(program.startHourUnixTime > timeNow){
                return 'FUTURO';
            } else {
                return 'PRESENTE';
            }
        },

        /**
         * Recorre y Actualiza el status de cada programa de la epg.
         * Este método debe utilizarse cuando se dispara el evento de cambio de programación
         */
        updateStatus: () => {
            for (let i = 0; i < Vue.prototype.$epg.data.length; i++) {
                const programs = Vue.prototype.$epg.data[i].programs;
                for (let j = 0; j < programs.length; j++) {
                    const program = programs[j];
                    Vue.prototype.$epg.data[i].programs[j].status = Vue.prototype.$epg.getStatus(program);
                }
            }
            //console.log('[ EPG ] updateStatus', Vue.prototype.$epg.data.filter(p => p.programs.filter(q => q.status === 'PRESENTE')))
        },

        /**
         * Actualiza y devuelve la lista de programas de un determinado canal
         */
        getUpdatedProgramsByChannel: (channel) => {
            Vue.prototype.$epg.updateStatus();
            return  Vue.prototype.$epg.data.filter(p => p.dvbTriplet === channel.dvbTriplet)[0].programs;
        },

        /**
         * Obtiene una lista de canales con el programa actual
         */
        getActualPrograms: ( filtersView = {canales: [], orden: 'REL'} ) => {
            return Vue.prototype.$epg.data.map((p) => {
                const index = p.programs.findLastIndex(
                                q => q.status === 'PRESENTE' && (filtersView.canales.includes(q.channel.id) === true || filtersView.canales.length === 0));
                            return index > -1 ? p.programs[index] : {};

                        });
        },

        /**
         * Retorna la programación de ayer a partir de una lista de programas
         */
        getProgrammingAyer: (programs, infoFecha, seconds) => {
            const fechaDesde = infoFecha.ayer;
            const fechaHasta = fechaDesde + seconds;
            const startTime = new Date().getTime() - seconds * 1000;
            return programs.filter(p => p.startHourUnixTime >= fechaDesde && p.startHourUnixTime < fechaHasta && p.endHourUnixTime * 1000 > startTime );
        },

        /**
         * Retorna la programación de ayer a partir de una lista de programas
         */
        getProgrammingHoy: (programs, infoFecha, ahora) => {
            const fechaDesde = infoFecha.hoy;
            return programs.filter(p => p.startHourUnixTime >= fechaDesde && p.startHourUnixTime <= ahora);
        },

        /**
         * Obtiene la programación pasada, presente y futura de un canal determinado
         * inBloks sirve para establecer si quiero la información separada en bloques hoy y ayer
         */
        getProgrammingByChannel: (dvbTriplet, inBlocks = false) => {
            const ahora = Date.now() / 1000;
            const fechaDesde = ahora - (60 * 60 * 24); // 24 hs para atrás
            const fechaHasta = ahora + (60 * 60 * 8); // 8 hs para adelante
            const programs = Vue.prototype.$epg.data.filter(p => p.dvbTriplet === dvbTriplet)[0].programs;

            if(inBlocks){
                const infoFecha = telecentro.tplay.core.utiles.getInfoFecha();
                const seconds = telecentro.tplay.core.utiles.SECONDS_EQ_24_HOURS;
                return {
                    ayer: Vue.prototype.$epg.getProgrammingAyer(programs, infoFecha, seconds),
                    hoy: Vue.prototype.$epg.getProgrammingHoy(programs, infoFecha, ahora),
                }
            } else {
                return programs.filter(p => p.startHourUnixTime >= fechaDesde && p.startHourUnixTime <= fechaHasta);
            }
        },

        /**
         * Obtiene el programa actual de un canal determinado
         */
        getActualProgramByChannel: (dvbTriplet) => {
            const actualPrograms = Vue.prototype.$epg.getActualPrograms();
            return actualPrograms.filter(p => p.channel.dvbTriplet === dvbTriplet)[0];
        },

        /**
         * Retorna un objeto epg reducido para guia
         * De esta forma se obtiene una mejora en la performance del manejo de memoria reactiva
         */
        getEpgForGuide: () => {
            return Vue.prototype.$epg.data.map((item, index) => {
                const channel = {
                    id: item.id,
                    name: item.name,
                    lcn: item.lcn,
                    dvbTriplet: item.dvbTriplet,
                    logo: item.logo,
                    logoCanal: item.logoCanal,
                    idChannel: item.id,
                    selected: item.selected,
                    programs: item.programs.map((e, eIndex) => {
                        return {
                            isFakeProgram: e.isFakeProgram,
                            //actors: e.actors,
                            //audio: e.audio,
                            //categoria: e.categoria,
                            //certificate: e.certificate,
                            //certificateCode: e.certificateCode,
                            //csasname: e.csasname,
                            //director: e.director,
                            //dispose: e.dispose,
                            //duracionTotalSegundos: e.duracionTotalSegundos,
                            //edad: e.edad,
                            //endHour: e.endHour,
                            //fechaFin: e.fechaFin,
                            fechaInicio: e.fechaInicio,
                            //grabado: e.grabado,
                            //recordData: e.recordData,
                            //sub: e.sub,
                            //synopsis: e.synopsis,
                            idAsset: e.idAsset,
                            title: e.title,
                            poster: e.poster,
                            status: e.status,
                            channel: e.channel,
                            startHourUnixTime: e.startHourUnixTime,
                            endHourUnixTime: e.endHourUnixTime,
                            startHour: e.startHour,
                        }
                    }),
                };
                return channel;
            });
        },

        /**
         * Retorna un objeto epg reducido solo con la lista de canales
         * De esta forma se obtiene una mejora en la performance del manejo de memoria
         */
        getListChannels: () => {
            return Vue.prototype.$epg.data.map((item, index) => {
                return {
                    id: item.id,
                    name: item.name,
                    lcn: item.lcn,
                    dvbTriplet: item.dvbTriplet,
                    logo: item.logo,
                    logoCanal: item.logoCanal,
                    idChannel: item.id,
                    selected: item.selected,
                }
            });
        },

        /**
         * Retorna un objeto epg reducido para guia
         * De esta forma se obtiene una mejora en la performance del manejo de memoria reactiva
         */
        getProgramsByChannel: (dvbTriplet) => {
            return Vue.prototype.$epg.data.filter(p => p.dvbTriplet === dvbTriplet)[0].programs;
        },

        getProgramByIdAsset: (idAsset) => {
            const programs = Vue.prototype.$epg.data.flatMap((item,index) => {
                return item.programs;
            })

            return programs.filter(p => p.idAsset == idAsset);
        }

    };



};

export default epg;
