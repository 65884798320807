<template>
  <div id="tplay" class="tplay-web">
    <Header v-if="isLogin" />

    <Breadcrumb
      v-if="isLogin"
      :class="{ 'display-none': currentRouteName === 'home' }"
    ></Breadcrumb>

    <router-view />

    <ModalGroupVod v-if="isLogin" />
    <ModalGroupTv v-if="isLogin" />
    <ModalMovies v-if="isLogin" />
    <ModalSeries v-if="isLogin" />
    <ModalTV v-if="isLogin" />
    <ModalPIN ref="modalPin" v-if="isLogin" />

    <ModalConfirm ref="modalConfirm" />

    <Player v-if="isLogin" ref="player" />

    <Toast />

    <ModalConcurrency v-if="isLogin" />

    <div
      v-show="loader"
      class="loader-wrapper loader-full"
      :style="'position: fixed!important; width:100%;height:100%;top:0;left:0;z-index:99999999999;'"
    >
      <div class="loader" title="Cargando ...">
        <svg class="circular-loader" viewBox="25 25 50 50">
          <circle
            class="loader-path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke="#09f"
            stroke-width="4"
          />
        </svg>
      </div>
    </div>

    <div v-if="isLogin" class="bottom-legals bottom-legals-footer">
      <p>Telecentro Play | Versión {{ version }}</p>
      <p>
        &copy; {{ new Date().getFullYear() }} Telecentro S.A. Todos los derechos
        reservados. -
        <a
          v-on:click="terminosYCondiciones"
          href="https://telecentro.com.ar/terminos"
          title="Términos y Condiciones"
          target="_blank"
          rel="noopener"
          >Términos y Condiciones</a
        >
        <a
          v-on:click="ayuda"
          href="https://ayuda.telecentro.com.ar/telecentro-play/"
          title="Ayuda"
          target="_blank"
          rel="noopener"
          >Ayuda</a
        >
      </p>
    </div>
  </div>
</template>

<script>
const pjson = require("../package.json");

import * as storeCast from "@/observables/cast";

export default {
  name: "App",

  components: {
    Header: () => import("@/components/Header.vue"),
    Breadcrumb: () => import("@/components/Breadcrumb.vue"),
    ModalGroupVod: () => import("@/components/ModalGroupVod.vue"),
    ModalGroupTv: () => import("@/components/ModalGroupTv.vue"),
    ModalMovies: () => import("@/components/Modal-MOVIE.vue"),
    ModalSeries: () => import("@/components/Modal-SERIES.vue"),
    ModalTV: () => import("@/components/Modal-TV.vue"),
    ModalPIN: () => import("@/components/Modal-PIN.vue"),
    Player: () => import("@/components/Player.vue"),
    Toast: () => import("@/components/Toast.vue"),
    ModalConfirm: () => import("@/components/Modal-confirm.vue"),
    ModalConcurrency: () => import("@/components/ModalConcurrency.vue"),
  },

  provide() {
    return {
      modalConfirmShow: this.modalConfirmShow,
      getPlayer: this.getPlayer,
      isModalPinOpened: this.isModalPinOpened,
    };
  },

  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
    currentRouteName() {
      return this.$route.name.trim();
    },
  },

  data() {
    return {
      isLogin: false,
      loader: false,
    };
  },

  mounted() {
    console.log("Telecentro play APP VERSION: ", pjson.version);
    this.$bus.$on("show-loader", this.showLoader);
    this.$bus.$on("cast-initlisteners", () => {
      this.initListeners();
    });
    this.$bus.$on("cast-removelisteners", () => {
      this.removeListeners();
    });

    const self = this;

    let nuevaVersionPendiente = null;

    this.$bus.$on("player:close", function () {
      if (!nuevaVersionPendiente) return;

      nuevaVersionPendiente = null;

      self.onVersionDisponible();
    });

    telecentro.tplay.core.biz.versionUpdate.subscribe(function (v) {
      //Si el player está abierto, muestro el cartel recién cuando se cierre.
      if (self.getPlayer().isActive()) {
        nuevaVersionPendiente = v;
      } else {
        self.onVersionDisponible(v);
      }
    });
    // chequeo si existe algún dispositivo cast disponible
    window["__onGCastApiAvailable"] = (isAvailable) => {
      if (isAvailable && typeof cast !== "undefined" && cast) {
        storeCast.setSupport(true);
        tplay.ccast.remotePlayer = new cast.framework.RemotePlayer();
        tplay.ccast.remotePlayerController =
          new cast.framework.RemotePlayerController(tplay.ccast.remotePlayer);
        // Inicio el cast
        const options = {};
        options.receiverApplicationId = "E5A0E3D7";
        options.autoJoinPolicy = chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED;
        options.androidReceiverCompatible = true;
        cast.framework.CastContext.getInstance().setOptions(options);
        // this.initListeners();
      } else {
        storeCast.setSupport(false);
      }
    };

    telecentro.tplay.core.checkLogin(async (token) => {
      if (token) {
        const agent = process.env.VUE_APP_AGENT_URL_GRAFANA;
        const key = process.env.VUE_APP_APIKEY_GRAFANA;
        const app = process.env.VUE_APP_GRAFANA_NAME_APP;
        const version = process.env.VUE_APP_VERSION;
        try {
          await tplay.initGrafana(agent, key, app, version);
          await tplay.setGrafanaUser();
          await tplay.startGrafanaSession();
        } catch (error) {
          console.error("No se pudo inicializar Grafana Faro.");
        }
      }
    });
  },

  methods: {
    initListeners() {
      tplay.ccast.remotePlayerController.addEventListener(
        cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
        () => this.$bus.$emit("cast-switchplayer")
      );
      tplay.ccast.remotePlayerController.addEventListener(
        cast.framework.RemotePlayerEventType.MEDIA_INFO_CHANGED,
        () => this.$bus.$emit("cast-mediachanged")
      );
      tplay.ccast.remotePlayerController.addEventListener(
        cast.framework.RemotePlayerEventType.LIVE_SEEKABLE_RANGE_CHANGED,
        (event) => this.$bus.$emit("liveseekchanged", event)
      );
      /*
		this.remotePlayerController.addEventListener(
			cast.framework.RemotePlayerEventType.CAN_SEEK_CHANGED,
			() => {}
		);

		this.remotePlayerController.addEventListener(
			this.events.isPaused,
			() => {}
		);

		this.remotePlayerController.addEventListener(
			this.events.isMuted,
			() => {}
		);

		this.remotePlayerController.addEventListener(
			this.events.isVolumeChanged,
			() => {}
		);

		this.remotePlayerController.addEventListener(
			cast.framework.RemotePlayerEventType.IS_PLAYING_BREAK_CHANGED,
			() => {}
		);

		this.remotePlayerController.addEventListener(
			cast.framework.RemotePlayerEventType.WHEN_SKIPPABLE_CHANGED,
			() => {}
		);

		this.remotePlayerController.addEventListener(
			cast.framework.RemotePlayerEventType.CURRENT_BREAK_CLIP_TIME_CHANGED,
			() => {}
		);

		this.remotePlayerController.addEventListener(
			cast.framework.RemotePlayerEventType.BREAK_CLIP_ID_CHANGED,
			() => {}
		); */
    },
    removeListeners() {
      if (tplay.ccast.remotePlayerController) {
        tplay.ccast.remotePlayerController.removeEventListener(
          cast.framework.RemotePlayerEventType.LIVE_SEEKABLE_RANGE_CHANGED
        );
        tplay.ccast.remotePlayerController.removeEventListener(
          cast.framework.RemotePlayerEventType.LIVE_SEEKABLE_RANGE_CHANGED
        );
      }
    },
    showLoader(set) {
      const self = this;
      self.loader = set;
    },

    documentClick(event) {
      const el = document.querySelector(".dropdown.show");

      if (el) {
        const target = event.target;

        if (
          (el !== target && !el.contains(target)) ||
          target.classList.contains("dropdown-item")
        ) {
          el.classList.toggle("show");
        }
      }
    },

    modalConfirmShow: function (opt) {
      return this.$refs.modalConfirm.show(opt);
    },

    isModalPinOpened: function () {
      return this.$refs.modalPin.isModalPinOpened();
    },

    getPlayer: function () {
      return this.$refs.player;
    },

    onContenidoPremium() {
      //Cierro el Player:
      this.getPlayer().onControlClose();
      tplay.Player.animateHide();

      this.modalConfirmShow({
        type: "info",
        title: "Contenido premium",

        content: `<p>
          Este contenido es premium.<br>
				  Suscribite ahora ingresando a <a href="https://telecentro.com.ar/packs-adicionales" title="Comprar Pack en Sucursal Virtual" target="_blank" rel="noopener">Sucursal Virtual</a>
				  <i class="zmdi zmdi-open-in-new"></i>.
				</p>`,

        confirm: function () {},
      });
    },

    onContenidoAdultos() {
      //Cierro el Player:
      this.getPlayer().onControlClose();
      tplay.Player.animateHide();

      this.modalConfirmShow({
        type: "info",
        title: "Contenido bloqueado",

        content: `<p>
				  Este contenido se encuentra bloqueado por el control parental.
				</p>`,

        confirm: function () {},
      });
    },

    onVersionDisponible(v) {
      this.modalConfirmShow({
        type: "confirm",
        title: "Nueva versión disponible",

        content: `<p>
				  Hay una nueva versión disponible de Telecentro PLAY.<br>
				  ¿Querés actualizar ahora?
				</p>`,

        cancelText: "No",
        acceptText: "Sí",

        confirm: function (ok) {
          if (ok) {
            // location.reload(true);
            //A veces falla el true y se sirve el archivo desde cache.
            //Así que uso la versión para forzar la actualización.

            const params = telecentro.tplay.core.utiles.queryStringToHash(
              window.location.search
            );
            params.v = v;
            const qs = telecentro.tplay.core.utiles.hashToQueryString(params);

            //Elimino todos los parámetros de la url original y agrego los nuevos:
            window.location =
              window.location.href.replace(/([^?]*)(\?.*)$/g, "$1") + "?" + qs;
          }
        },
      });
    },
    trasnformMesaggePremium() {},

    terminosYCondiciones() {
      tplay.sendGrafanaEvent(
        tplay.grafana.event.BUTTON,
        { name: "Términos y Condiciones", screen: this.$route.name },
        tplay.grafana.actions.ENTER
      );
    },
    ayuda() {
      tplay.sendGrafanaEvent(
        tplay.grafana.event.BUTTON,
        { name: "Ayuda", screen: this.$route.name },
        tplay.grafana.actions.ENTER
      );
    },
  },

  created() {
    const self = this;

    document.addEventListener("click", this.documentClick, true);

    telecentro.tplay.core.setObserverError(function (msg) {
      //Primero chequeo si es algún mensaje especial:
      if (msg == "CONTENIDO_PREMIUM") {
        self.onContenidoPremium();
      } else {
        self.$bus.$emit("show-toast", "error", msg);
        //Cierro el Player:
        self.getPlayer().onControlClose();
        tplay.Player.animateHide();
      }
    });

    telecentro.tplay.core.setObserverSuccess(function (msg) {
      self.$bus.$emit("show-toast", "success", msg);
    });

    telecentro.tplay.core.setObserverInternetDisconnected(function (isOffline) {
      console.log("Explorador sin internet");
      const msg = isOffline ? "Explorador sin internet." : "";
      self.$bus.$emit("show-toast", "info", msg, 0);
    });
  },

  beforeDestroy() {
    this.$bus.$emit("cast-removelisteners");
    this.$bus.$off("cast-removelisteners", () => {
      this.removeListeners();
    });
    this.$bus.$off("cast-initlisteners", () => {
      this.initListeners();
    });
    document.removeEventListener("click", this.documentClick, true);
  },

  watch: {
    $route(to) {
      return to.name === "login"
        ? (this.isLogin = false)
        : (this.isLogin = true);
    },
  },
};
</script>
