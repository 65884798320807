/* eslint-disable no-undef */
import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const breadcrumb = {
    inicio: { path: '/inicio', title: 'Volver al inicio', text: 'Inicio' },
    peliculas: { path: '/peliculas', title: 'Volver a películas', text: 'Películas' },
    series: { path: '/series', title: 'Volver a series', text: 'Series' },
    tv: { path: '/tv-en-vivo', title: 'Volver a Tv en vivo', text: 'TV en vivo' },
    guia: { path: '/guia', title: 'Volver a guía', text: 'Guía' },
    guiaTradicional: { path: '/guia', title: 'Volver a guía', text: 'Guía' },
    ultimas24Hs: { path: '/24-horas', title: 'Volver a últimas 24 hs', text: 'Últimas 24 hs' },
    miContenido: { path: '/mi-contenido', title: 'Volver a mi contenido', text: 'Mi contenido' },
    controlParenal: { path: '/mi-contenido', title: 'Volver a control parental', text: 'Control parental' },
    busqueda: { text: 'Búsqueda' },
};

const maintenanceMode = telecentro.tplay.core.cfg.maintenanceMode;

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    linkExactPathActiveClass: 'active',
    routes: [
        {
            path: '/',
            redirect: {
                path: '/inicio'
            }
        },
        {
            path: '/inicio',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [breadcrumb.inicio]
            }
        },
        {
            path: '/peliculas',
            name: 'movies',
            component: () => import('@/views/Movies.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [breadcrumb.inicio, breadcrumb.peliculas]
            }
        },
        {
            path: '/peliculas/:genreId/:genreName/:providerId?',
            name: 'all-movies',
            component: () => import('@/views/Movies-ALL.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [breadcrumb.inicio, breadcrumb.peliculas, ':genreName']
            }
        },
        {
            path: '/series',
            name: 'series',
            component: () => import('@/views/Series.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [breadcrumb.inicio, breadcrumb.series]
            }
        },
        {
            path: '/series/:genreId/:genreName/:providerId?',
            name: 'all-series',
            component: () => import('@/views/Series-ALL.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [breadcrumb.inicio, breadcrumb.series, ':genreName']
            }
        },
        {
            path: '/tv-en-vivo',
            name: 'tv-live',
            component: () => import('@/views/Tv-live.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [breadcrumb.inicio, breadcrumb.tv]
            }
        },
        // {
        //     path: '/guia',
        //     name: 'guide',
        //     component: () => import('@/views/Guide.vue'),
        //     meta: {
        //         requiresAuth: true,
        //         breadcrumb: [breadcrumb.inicio, breadcrumb.guia]
        //     }
        // },
        {
            path: '/guia',
            name: 'guideTrad',
            component: () => import('@/views/GuideTrad.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [breadcrumb.inicio, breadcrumb.guiaTradicional]
            }
        },
        {
            path: '/24-horas',
            name: '24-hours',
            component: () => import('@/views/Hours-24.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [breadcrumb.inicio, breadcrumb.ultimas24Hs]
            }
        },
        {
            path: '/24-horas/:channelId/:channelName',
            name: '24-horas-filtro',
            component: () => import('@/views/Hours-24.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [breadcrumb.inicio, breadcrumb.ultimas24Hs]
            }
        },
        {
            path: '/mi-contenido',
            name: 'my-content',
            component: () => import('@/views/My-content.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [breadcrumb.inicio, breadcrumb.miContenido]
            }
        },
        {
            path: '/buscador/:str',
            name: 'buscador',
            component: () => import('@/views/Search.vue'),
            meta: {
                requiresAuth: true,
                breadcrumb: [breadcrumb.inicio, breadcrumb.busqueda]
            }
        },
        {
            path: '/control-parental',
            name: 'parental-control',
            component: () => import('@/views/Parental-control.vue'),
            meta: {
                requiresParentalPin: true,
                requiresAuth: true,
                breadcrumb: [breadcrumb.inicio, breadcrumb.controlParenal]
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => (!maintenanceMode) ? import('@/views/Login.vue') : import('@/views/MaintenanceMode.vue'),
        },
        {
            path: '/404',
            name: '404',
            component: () => import('@/views/404.vue'),
        },
        {
            path: '*',
            redirect: {
                path: '/404'
            }
        }
    ]
});

router.beforeEach((to, from, next) => {

    // Si la sección requiere estar autenticado
    if (to.matched.some(record => record.meta.requiresAuth)) {

        // Si el usuario no está autenticado lo envío al login
        telecentro.tplay.core.setObserverPerdidaSession(function () {
            if (from.path != '/login') {
                window.location = window.location.protocol + '//' + window.location.host + '/login';
            }
        });

        // Si el usuario está autenticado lo redirecciono a la sección solicitada
        telecentro.tplay.core.checkLogin(function (token) {
            if (token) {
                // // Controlo si el usuario necesita pin de control parental
                //  Se modifico la forma de verificar el control parental, se deja codigo en cambio de tener que hacer rollback.
                // if (to.matched.some(record => record.meta.requiresParentalPin)) {

                //     Vue.prototype.$bus.$emit('modal-pin:show', function () {
                //         next();
                //     });

                // } else {
                //     next();
                // }
                next();
            }
        });

    } else {

        // next();
        // Si el usuario está autenticado lo redirecciono a la sección solicitada
        telecentro.tplay.core.checkLogin(function (token) {
            if (token && to.path != '/login') {
                next();
            } else {
                next({
                    path: '/inicio',
                });
            }
        }, () => {
            next();
        });
    }
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
    let playerWindow = document.querySelector('.player');
    if(playerWindow){
        const isEmbedded = playerWindow.classList.contains('player-embedded') || playerWindow.classList.contains('player-float');
        if(isEmbedded && to.path.indexOf('/guia') === -1){
            playerWindow.classList.remove('player-embedded');
            playerWindow.classList.add('player-float');
        } else if(isEmbedded && to.path.indexOf('/guia') != -1)  {
            playerWindow.classList.add('player-embedded');
            playerWindow.classList.remove('player-float');
        }
    }

    tplay.sendGrafanaEvent(tplay.grafana.event.NAVIGATE, { from: from.name, to: to.name }, tplay.grafana.actions.UI);
    window.scrollTo(0, 0);
})

export default router;
