/* eslint-disable no-undef */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import eventBus from './plugins/event-bus';
import epg from './plugins/epg';
// import mux from './../public/assets/libs/veygo/mux';
// import vip from './../public/assets/libs/veygo/vip-player';
// import vipConfig from './../public/assets/libs/veygo/vip-config';

// console.log('Telecentro play client:', process.env.VUE_APP_VERSION);

import sentry from './sentry.js';

require('@/assets/styles/main-20201203-prod.css');

telecentro.tplay.core.api.configVeygoAnalytics(window.vip.CONFIG_VIP_ANALYTICS_API_KEY, window.vip.CONFIG_VIP_VERSION);

Vue.config.productionTip = false;

//Vue.use(sentry);

Vue.use(eventBus);
Vue.use(epg);

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
